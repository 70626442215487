<template>
  <div>
    <!-- Titlebar -->
    <div class="parallax-container">
      <div class="parallax titlebar">
        <div id="titlebar">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <h2>عن هكتار</h2>
                <span> </span>

                <!-- Breadcrumbs -->
                <nav id="breadcrumbs">
                  <ul>
                    <li>
                      <router-link to="/">الرئيسية</router-link>
                    </li>
                    <li>عن هكتار</li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>

      <parallax :speed-factor="0.3" breakpoint="(min-width: 80px)">
        <img :src="titleBg" />
      </parallax>
    </div>

    <!-- Container -->
    <div v-if="aboutHectar.text" class="container">
      <div class="row justify-content-center">
        <!-- Contact Form -->
        <div class="col-md-12" v-html="sanitize(aboutHectar.text)"></div>
        <!-- Contact Form / End -->
      </div>
    </div>
    <!-- Container / End -->

    <div class="margin-top-55"></div>
  </div>
</template>

<script>
import Parallax from 'vue-parallaxy'
import sanitizers from '@/mixins/sanitizers.js'
import { userService } from '../../services'

export default {
  name: 'AboutHectar',
  mixins: [sanitizers],
  components: {
    Parallax
  },
  data() {
    return {
      titleBg: require('../../assets/images/home-parallax-2.jpg'),
      aboutHectar: {}
    }
  },
  created() {
    this.getAboutUs()
  },
  methods: {
    getAboutUs() {
      userService
        .getAboutUs()
        .then(res => {
          this.aboutHectar = res.data.result
        })
        .catch(() => {
          this.$toasted.show('خطأ في تحميل عن هكتار يرجى المحاوله مجددا', {
            duration: 2000,
            type: 'error',
            icon: 'fa-times'
          })
        })
    }
  }
}
</script>

<style>
div.Masthead:after {
  background-color: rgb(61, 186, 126);
  opacity: 0.9;
}
.margin-top-55 {
  height: 5px;
  margin-top: 100px !important;
}
</style>
